<template>
  <iframe
    v-if="esmobil"
    id="naat"
    frameborder="0"
    width="100%"
    :height="height"
    class="pz-large-margin"
    :src="frame_route"
    sandbox="allow-forms allow-modals allow-popups allow-same-origin allow-scripts"
    allow="geolocation; microphone; camera;"
  />
  <el-col
    v-else
    class="pz-large-margin text-lg-center"
  >
    <pz-title>
      <span slot="title">
        Escanear Codigo QR para continuar el proceso en su Mobile <br>
      </span>
    </pz-title>
    <el-row :gutter="20">
      <el-col
        :xl="{span: 24}"
        :lg="{span: 24}"
        :md="{span: 24}"
        :sm="{span: 24}"
        :xs="{span: 24}"
      >
        <vue-qrcode
          :value="frame_route"
          :options="{ width: 200 }"
        />
      </el-col>
    </el-row>
  </el-col>
</template>

<script>

import {mapGetters} from "vuex";
import { isMobile} from '@/commons/utils/functions';
import VueQrcode from '@chenfengyuan/vue-qrcode';

export default {
    name: "PzNaat",
    components: {VueQrcode},
    props: {
        route: {
            require: true,
            default: null,
            type: [String, URL]
        },
        redirect: {
            require: true,
            default: 'Prestamos',
            type: [String]
        },
    },
    data() {
        return {
            frame_route: this.route,
            height: '600px',watch: {
                route(value) {
                    this.frame_route = value;
                },
                frame_route(value) {
                    console.log('change form route');
                    console.log(value);
                },

            },
        }
    },
    computed: {
        ...mapGetters(['alias']),
        esmobil(){
            return isMobile()
        }
    },

    created() {
        window.addEventListener("message", (event) => {

            console.log(event)
            console.log(event.origin)

            const URL_FAD = 'https://uatwebfad4.firmaautografa.com/main';
            if (event.origin ==URL_FAD) {
                if (event.data.event === 'PROCESS_STARTED') {
                    console.log('proceso iniciado');
                } else if (event.data.event === 'PROCESS_ENDED') {
                    console.log('proceso terminado');
                } else if (event.data.event === 'SIGNATURE_COMPLETED') {
                    console.log('proceso de firma completo');
                } else if (event.data.event === 'RELOAD_PROCESS') {
                    console.log('reinicia el proceso');
                }
            } else {
                return;
            }
        }, false);
    },
    methods: {
    },

}
</script>
